import Amplify  from 'aws-amplify';

Amplify.configure({
    Auth: {

        region: 'eu-west-2',

        userPoolId: 'eu-west-2_DHttvQ5ba',

        userPoolWebClientId: '52inso6i2h2i2oabattjpvttic',

        mandatorySignIn: false,
        cookieStorage: {
            domain: '.maisiesadler.co.uk',
            path: '/',
            expires: 365,
            secure: true
        },

        oauth: {
            domain: 'auth.maisiesadler.co.uk',
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'https://www.account-tracker.maisiesadler.co.uk/',
            redirectSignOut: 'https://www.account-tracker.maisiesadler.co.uk/',
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    }
});
