import React from 'react';
import { SelectedMenuItem } from './SideMenuDefinition';
import { CategorisedTransactionsComponent, HomePageComponent, ManageRulesComponent, UncategorisedTransactionsComponent } from './Pages';

export interface RoutePageComponentProps {
  selectedMenuItem: SelectedMenuItem
}

export interface RoutePageComponentState {
}

export class RoutePageComponent extends React.Component<RoutePageComponentProps, RoutePageComponentState> {

  componentDidMount() {
  }

  onSearch(id: string) {
    this.setState({ accountId: id })
  }

  render() {
    const { selectedMenuItem } = this.props;

    return (<>
      {selectedMenuItem.breadcrumb[0] === 'Home' && <HomePageComponent />}
      {!!selectedMenuItem.accountId && <>
        {selectedMenuItem.breadcrumb[0] === 'Raw Transactions' && <UncategorisedTransactionsComponent accountId={selectedMenuItem.accountId} />}
        {selectedMenuItem.breadcrumb[0] === 'Mapped Transactions' && <CategorisedTransactionsComponent accountId={selectedMenuItem.accountId} />}
        {selectedMenuItem.breadcrumb[0] === 'Manage Account Rules' && <ManageRulesComponent accountId={selectedMenuItem.accountId} />}
      </>}
    </>);
  }
}
