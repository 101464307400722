import React from 'react';
import { session } from '../auth';
import { logger } from '../utils';
import { TrueLayerStatusComponent } from './TrueLayerStatusComponent';

export class HomePageComponent extends React.Component {

  state = { user: null, customState: null, loggedIn: false, accountId: '' };

  componentDidMount() {

    session.onUserUpdated(user => {
      this.setState({ loggedIn: user !== null });
      logger.info('session from server', user)
    })

    session.hubListen()
  }

  render() {
    const { loggedIn } = this.state;

    if (!loggedIn) {
      return <div
        color="light-2"
        onClick={() => session.hostedUI()}>
        Login pls
      </div>
    }

    return (
      <div
        color="light-2">
        welcome
        <TrueLayerStatusComponent /> 
      </div>
    );
  }
}
