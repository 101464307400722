import { Button, Divider, Dropdown, Input, Menu, Radio, Space, Table, Typography } from 'antd';
import { ArrowLeftOutlined, CheckOutlined, CloseOutlined, DownOutlined, QuestionCircleOutlined, UpOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { CancelToken } from '../api/HttpClient';
import { TransactionApi, Rule } from '../api/TransactionApi';
import { logger } from '../utils';

export interface ManageRulesComponentProps {
    accountId: string
}

const Rules = ({ accountId }: { accountId: string }) => <div>
    <h2>What is the rules for?</h2>
    <p>These rules will be used to categorise the transactions.</p>
    <p>Rules can be created on either the transaction <i>source</i> or <i>description</i> and the transaction is matched using the match value.</p>
    <pre><code>{`{
    id: '213',
    timestamp: '2018-03-19T00:00:00',
    description: 'Sender Name - Sent from xyz',
    transactionType: 'CREBIT',
    transactionCategory: 'TRANSFER',
    amount: 82,
    source: 'tl_${accountId}-Your Name'
}`}</code></pre>
    <p>The transaction <i>source</i> is set to the source of the transaction - in this example the `tl_` prefix indicates it is via TrueLayer.</p>
    <p>The transaction <i>description</i> is set per transaction, for bank transactions this will match the transaction description from the bank. </p>

    <p>Manual transactions can set both <i>source</i> and <i>description</i>.</p>
</div>

type RuleEdits = (Rule & {
})

type RuleWithPriority = (Rule & {
    id: number,
    editing: boolean,
    dirty: boolean,
    edits?: Partial<RuleEdits>,
    validation?: string,
    deleted: boolean,
})

function validateRule(rule: RuleWithPriority): string | undefined {
    if (!rule.type || (rule.type !== 'source' && rule.type !== 'description')) {
        return `Type must be 'source' or 'description'`
    }
    if (!rule.match || rule.match === '') {
        return 'Match invalid'
    }
    if (!rule.category || rule.category === '') {
        return 'Category invalid'
    }

    return undefined
}

function applyRuleUpdates(rule: RuleWithPriority): RuleWithPriority {
    if (rule.deleted) return rule;

    let updated: RuleWithPriority = { ...rule, ...rule.edits }
    const validation = validateRule(updated)
    if (!validation) {
        updated = { ...updated, editing: false, dirty: true, edits: undefined }
    }
    return { ...updated, validation }
}

function undoRuleUpdates(rule: RuleWithPriority): RuleWithPriority {
    return { ...rule, editing: false, dirty: true, edits: undefined, validation: undefined }
}

function updateRuleById(rules: RuleWithPriority[], rule: RuleWithPriority, update: Partial<RuleWithPriority>): RuleWithPriority[] {
    const ruleIndex = rules.findIndex(x => x.id === rule.id)
    const newRule: RuleWithPriority = { ...rules[ruleIndex], ...update }
    const newRules = [...rules]

    newRules[ruleIndex] = newRule

    return newRules
}

export interface ManageRulesComponentState {
    response?: { rules: Rule[] }
    loadedAccountId?: string
    error?: string
    rules: RuleWithPriority[]
    matchedRuleId?: number
}

function rulesWithPriority(rules?: Rule[]): RuleWithPriority[] {
    return rules?.map((r, i) => ({ ...r, id: i, editing: false, dirty: false, deleted: false })) || []
}

function asRules(rules: RuleWithPriority[]): Rule[] {
    return rules
        .filter(r => !r.deleted)
        .map(r => ({
            type: r.type,
            match: r.match,
            category: r.category,
        }))
}

const TestRulesComponents = ({ rules, onUpdateMatchedRuleId }
    : { rules: RuleWithPriority[], onUpdateMatchedRuleId: (id: number | undefined) => void }) => {
    const [testValue, setTestValue] = useState('')
    const [matchType, setMatchType] = useState('description')
    const [matchedRule, setMatchedRule] = useState<{ id: number, category: string } | undefined>(undefined)

    function calculate(): { id: number, category: string } | undefined {
        for (let i = 0; i < rules.length; i++) {
            const rule = rules[i]
            if (rule.type !== matchType) continue
            if (rule.deleted) continue
            const result = new RegExp(rule.match).exec(testValue)
            if (result !== null) {
                // console.log('matched', rule, result)
                return {
                    id: rule.id,
                    category: rule.category,
                }
            }
        }
    }

    const result = calculate()
    if (matchedRule?.id !== result?.id) {
        setMatchedRule(result)
        onUpdateMatchedRuleId(result?.id)
    }

    return <>
        <h2>Test the rules!</h2>
        <Input placeholder='Enter text to test against rules' onKeyUp={val => {
            const value = (val.target as any).value
            setTestValue(value)
        }} />
        <Radio.Group onChange={val => setMatchType(val.target.value)} value={matchType}>
            <Radio value='description'>Description</Radio>
            <Radio value='source'>Source</Radio>
        </Radio.Group>
        {matchedRule && <div style={{ marginTop: '10px' }}>Matched category <b>{matchedRule?.category}</b></div>}
        {!!testValue && !matchedRule && <div style={{ marginTop: '10px' }}><i>No match</i></div>}
    </>
}

export class ManageRulesComponent extends React.Component<ManageRulesComponentProps, ManageRulesComponentState> {
    constructor(props: ManageRulesComponentProps) {
        super(props)
        this.state = {
            rules: [],
        };
    }

    private cancelToken?: CancelToken
    private _isCanceled: boolean = false

    loadRules(accountId: string) {
        this.cancelToken = new CancelToken();
        this._isCanceled = false
        TransactionApi.rules.get(accountId, this.cancelToken)
            .then(response => {
                if (response.statusCode === 200) {
                    this.setState({
                        response: response.data,
                        rules: rulesWithPriority(response.data?.rules),
                        loadedAccountId: accountId,
                    });
                } else {
                    this.setState({ error: (response.data as any).message || ':(' })
                }
            })
            .catch(err => {
                if (!this._isCanceled) {
                    logger.warn('get transactions cancelled', err);
                }
            });
    }

    componentWillUnmount() {
        this._isCanceled = true;
        this.cancelToken?.cancel();
    }

    add() {
        const { rules } = this.state
        const newRules: RuleWithPriority[] = [
            ...rules,
            { type: 'description', match: '', category: '', id: rules.length, editing: true, dirty: true, deleted: false }]

        this.setState({
            rules: newRules,
        })
    }

    moveUp(row: RuleWithPriority) {
        const { rules } = this.state
        const ruleIndex = rules.findIndex(x => x.id === row.id)
        if (ruleIndex < 1) return
        const rule: RuleWithPriority = rules[ruleIndex]
        const newRules = [...rules]
        newRules[ruleIndex] = { ...newRules[ruleIndex - 1], dirty: true }
        newRules[ruleIndex - 1] = { ...rule, dirty: true }

        this.setState({
            rules: newRules,
        })
    }

    moveDown(row: RuleWithPriority) {
        const { rules } = this.state
        const ruleIndex = rules.findIndex(x => x.id === row.id)
        if (ruleIndex > rules.length - 1) return
        const rule: RuleWithPriority = rules[ruleIndex]
        const newRules = [...rules]
        newRules[ruleIndex] = { ...newRules[ruleIndex + 1], dirty: true }
        newRules[ruleIndex + 1] = { ...rule, dirty: true }

        this.setState({
            rules: newRules,
        })
    }

    setRuleEdit(rule: RuleWithPriority, update: Partial<RuleEdits>) {
        if (!rule.edits) rule.edits = {}
        const { rules } = this.state
        const newRules = updateRuleById(rules, rule, { edits: { ...rule.edits, ...update } })
        this.setState({ rules: newRules })
    }

    saveRowChanges(row: RuleWithPriority) {
        const rules = this.getUpdatedRows(row, applyRuleUpdates(row))
        this.setState({ rules })
    }

    deleteRow(rule: RuleWithPriority) {
        const update: Partial<RuleWithPriority> = { ...undoRuleUpdates(rule), deleted: true }
        this.updateRow(rule, update)
    }

    updateRow(row: RuleWithPriority, update: Partial<RuleWithPriority>) {
        const rules = this.getUpdatedRows(row, update)
        this.setState({ rules })
    }

    getUpdatedRows(row: RuleWithPriority, update: Partial<RuleWithPriority>): RuleWithPriority[] {
        const { rules } = this.state
        return updateRuleById(rules, row, update)
    }

    saveAllChanges() {
        const { rules } = this.state

        let newRules = rules;

        rules.forEach(r => {
            newRules = updateRuleById(newRules, r, applyRuleUpdates(r))
        })

        this.setState({ rules: newRules })
    }

    updateRows() {
        const { loadedAccountId, rules } = this.state
        if (rules.find(r => r.editing)) {
            return
        }
        if (rules.find(r => !!r.validation)) {
            console.log('Invalid rules found', rules)
            return
        }
        const updateRules = asRules(rules)
        TransactionApi.rules.post(loadedAccountId || '', updateRules)
            .then(_ => this.loadRules(loadedAccountId || ''))
            .catch(err => {
                logger.error('error updating rows', err);
            });
    }

    render() {
        const { accountId } = this.props
        const { rules, loadedAccountId, error, response, matchedRuleId } = this.state

        if (error) {
            return <>Error: {error}</>
        }

        if (loadedAccountId !== accountId) {
            this.loadRules(accountId)
            return <>Waiting</>
        }

        const dirty = !!rules.find(x => x.dirty)

        return <>
            <h2>Manage Account Rules</h2>
            <div>You are managing the rules for account '{loadedAccountId}', scroll down for a description of how this works.</div>
            {dirty && <Space style={{ marginBottom: '10px' }}>
                <Button onClick={() => this.setState({ rules: rulesWithPriority(response?.rules) })}>Undo</Button>
                <Button onClick={() => this.updateRows()}>Update</Button>
                <Button onClick={() => this.saveAllChanges()}>Save All Changes</Button>
            </Space>}

            <Table rowKey={t => t.id} dataSource={rules.filter(r => !r.deleted)}>
                <Table.Column title="Type" render={(_: any, row: RuleWithPriority) => <>
                    {row.editing && <Dropdown overlay={<Menu onClick={i => this.setRuleEdit(row, { type: i.key as any })}>
                        <Menu.Item key="source">source</Menu.Item>
                        <Menu.Item key="description">description</Menu.Item>
                    </Menu>}>
                        <Button>{row.edits?.type || row.type} <DownOutlined /></Button>
                    </Dropdown>}
                    {!row.editing && <span>{row.type}</span>}
                </>} />
                <Table.Column title="Match" render={(_: any, row: RuleWithPriority) => <>
                    {row.editing && <Input defaultValue={row.match} onKeyUp={val => this.setRuleEdit(row, { match: (val.target as any).value })} />}
                    {!row.editing && <span>{row.match}</span>}
                </>} />
                <Table.Column title="Category" render={(_: any, row: RuleWithPriority) => <>
                    {row.editing && <Input defaultValue={row.category} onKeyUp={val => this.setRuleEdit(row, { category: (val.target as any).value })} />}
                    {!row.editing && <span>{row.category}</span>}
                </>} />
                <Table.Column title="Status" key="status" render={(_: any, row: RuleWithPriority) => <>
                    {!row.editing && <>
                        {(!row.validation) && <CheckOutlined style={{ color: 'green' }} />}
                        {(!!row.validation && row.validation !== 'editing') && <Space>
                            <CloseOutlined style={{ color: 'red' }} />
                            <span>{row.validation}</span>
                        </Space>}
                    </>}
                    {row.editing && <Space>
                        {(!row.validation) && <QuestionCircleOutlined />}
                        {(!!row.validation && row.validation !== 'editing') && <Space>
                            <CloseOutlined style={{ color: 'red' }} />
                            <span>{row.validation}</span>
                        </Space>}
                    </Space>}
                </>} />
                <Table.Column title="" key="upordown" render={(_: any, row: RuleWithPriority, idx: number) => <>
                    <Space>
                        {idx > 0 && <Typography.Link onClick={() => this.moveUp(row)}><UpOutlined /></Typography.Link>}
                        {idx < rules.length - 1 && <Typography.Link onClick={() => this.moveDown(row)}><DownOutlined /></Typography.Link>}
                    </Space>
                </>} />
                <Table.Column title="" key="editorsave" render={(_: any, row: RuleWithPriority, idx: number) => <Space>
                    {row.editing && <Space>
                        <Typography.Link onClick={() => this.saveRowChanges(row)}>Save</Typography.Link>
                        <Typography.Link onClick={() => this.deleteRow(row)}>Delete</Typography.Link>
                    </Space>}
                    {!row.editing && <>
                        <Typography.Link onClick={() => this.updateRow(row, { editing: true })}>Edit</Typography.Link>
                    </>}
                    {row.id === matchedRuleId && <ArrowLeftOutlined style={{ color: 'green' }} />}
                </Space>} />
            </Table>
            <Button onClick={() => this.add()}>Add New Rule</Button>
            <Divider />
            <TestRulesComponents rules={rules} onUpdateMatchedRuleId={matchedRuleId => this.setState({ matchedRuleId })} />
            <Divider />
            <Rules accountId={loadedAccountId} />
        </>
    }
}
