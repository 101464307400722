import axios, { CancelTokenSource } from 'axios'
import { session } from '../auth';

export interface HttpResponse<T> {
  statusCode?: number;
  data?: T
}

export class CancelToken {
  signal: CancelTokenSource
  constructor() {
    this.signal = axios.CancelToken.source();
  }

  get token() {
    return this.signal.token;
  }

  cancel() {
    this.signal.cancel();
  }
}

export class HttpClient {
  constructor(private readonly hostName: string) {
  }

  public async Get<T>(path: string, cancelToken?: CancelToken): Promise<{ statusCode?: number, data?: any }> {
    const url = `${this.hostName}${path}`

    const { ok, cached } = this.tryGetFromSessionStorage<T>('GET', path)
    if (ok) return { statusCode: 200, data: cached }

    try {
      const authToken = await this.getAuthToken()

      // todo: add this into request and call from componentWillUnmount
      // https://www.freecodecamp.org/news/how-to-work-with-react-the-right-way-to-avoid-some-common-pitfalls-fc9eb5e34d9e/
      cancelToken = cancelToken || new CancelToken();

      const response = await axios.get<T>(url, {
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
        cancelToken: cancelToken.token
      })
      this.trySetFromSessionStorage('GET', path, response.data)
      return {
        statusCode: response.status,
        data: response.data,
      }
    } catch (error) {
      return this.handleAxiosError(error)
    }
  }

  public async Delete<T>(path: string, cancelToken?: CancelToken): Promise<{ statusCode?: number, data?: any }> {
    const url = `${this.hostName}${path}`
    try {
      const authToken = await this.getAuthToken()

      // todo: add this into request and call from componentWillUnmount
      // https://www.freecodecamp.org/news/how-to-work-with-react-the-right-way-to-avoid-some-common-pitfalls-fc9eb5e34d9e/
      cancelToken = cancelToken || new CancelToken();

      const response = await axios.delete<T>(url, {
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
        cancelToken: cancelToken.token
      })
      return {
        statusCode: response.status,
        data: response.data,
      }
    } catch (error) {
      return this.handleAxiosError(error)
    }
  }

  public async Post<T>(path: string, data: any, cancelToken?: CancelToken): Promise<{ statusCode?: number, data?: any }> {
    const url = `${this.hostName}${path}`
    try {

      const authToken = await this.getAuthToken()

      // todo: add this into request and call from componentWillUnmount
      // https://www.freecodecamp.org/news/how-to-work-with-react-the-right-way-to-avoid-some-common-pitfalls-fc9eb5e34d9e/
      cancelToken = cancelToken || new CancelToken();
      const response = await axios.post<T>(url, data, {
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
        cancelToken: cancelToken.token
      })
      return {
        statusCode: response.status,
        data: response.data,
      }
    } catch (error) {
      return this.handleAxiosError(error)
    }
  }

  private async getAuthToken(): Promise<string | null> {
    try {
      const user = await session.currentUser();
      if (user == null) return null;
      return user.signInUserSession.idToken.jwtToken
    } catch (e) {
      return null;
    }
  }

  private handleAxiosError(error: any): { statusCode?: number, data?: any } {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log('error response', error.response);
      return { statusCode: error.response.status, data: error.response.data }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
      console.log('error request', error.response);
      return {}
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
      return {}
    }
  }

  private tryGetFromSessionStorage<T>(method: string, path: string): { ok: boolean, cached?: T } {
    if (!window.location.href.startsWith('http://localhost')) {
      return { ok: false }
    }

    try {
      const val = window.sessionStorage.getItem(`${method}_${path}`)
      if (!val) return { ok: false }
      console.warn(`using cached value for ${method}_${path}`)
      return { ok: true, cached: JSON.parse(val) }
    } catch {
      return { ok: false }
    }
  }

  private trySetFromSessionStorage<T>(method: string, path: string, value: T): void {
    if (!window.location.href.startsWith('http://localhost')) {
      return
    }

    try {
      const val = JSON.stringify(value)
      window.sessionStorage.setItem(`${method}_${path}`, val)
    } catch {
    }
  }
}
