import { ReactElement } from 'react';
import { BankOutlined, BookOutlined, UserOutlined } from '@ant-design/icons';

interface MenuDefinition {
  type: 'menu' | 'submenu',
  title: string,
  icon?: ReactElement,
  menuitems?: { title: string, account_id: string }[]
}

export interface SelectedMenuItem {
  accountId?: string
  breadcrumb: string[]
}

export class SideMenu {

  buildMenu(accounts: { id: string, name: string }[]) {
    const menu: MenuDefinition[] = [{
      type: 'menu',
      title: 'Home',
    }, {
      type: 'submenu',
      title: 'Raw Transactions',
      icon: <BankOutlined />,
      menuitems: accounts.map(account => ({
        account_id: account.id,
        title: account.name || account.id,
      }))
    }, {
      type: 'submenu',
      title: 'Mapped Transactions',
      icon: <UserOutlined />,
      menuitems: accounts.map(account => ({
        account_id: account.id,
        title: account.name || account.id,
      }))
    }, {
      type: 'submenu',
      title: 'Manage Account Rules',
      icon: <BookOutlined />,
      menuitems: accounts.map(account => ({
        account_id: account.id,
        title: account.name || account.id,
      }))
    }]

    function getMenuItem(key: string): SelectedMenuItem {
      const breadcrumb = []

      const split = key.split('-').map(x => +x)
      let current = menu[split[0]]
      breadcrumb.push(current.title)

      if (split.length === 1 || !current.menuitems) {
        return { breadcrumb } // current
      }

      // return current.menuitems[split[1]]
      const menuItem = current.menuitems[split[1]]
      breadcrumb.push(menuItem.title)
      return { breadcrumb, accountId: menuItem.account_id }
    }

    return { menu, getMenuItem }
  }
}
