import { Button, Space, Table } from 'antd';
import React from 'react';
import { CancelToken } from '../api/HttpClient';
import { Transaction, TransactionApi, ViewTransactionsResponse } from '../api/TransactionApi';
import { logger } from '../utils';

export interface UncategorisedTransactionsComponentProps {
    accountId: string
}

export interface UncategorisedTransactionsComponentState {
    response?: ViewTransactionsResponse | undefined
    loadedAccountId?: string
    error?: string
    editing: boolean
}

export class UncategorisedTransactionsComponent extends React.Component<UncategorisedTransactionsComponentProps, UncategorisedTransactionsComponentState> {
    constructor(props: UncategorisedTransactionsComponentProps) {
        super(props)
        this.state = {
            editing: false,
        };
    }

    private cancelToken?: CancelToken
    private _isCanceled: boolean = false

    loadAccountTransactions(accountId: string) {
        this.cancelToken = new CancelToken();
        this._isCanceled = false
        TransactionApi.view(accountId, this.cancelToken)
            .then(response => {
                if (response.statusCode === 200) {
                    this.setState({
                        response: response.data,
                        loadedAccountId: accountId
                    });
                } else {
                    this.setState({ error: (response.data as any).message || ':(' })
                }
            })
            .catch(err => {
                if (!this._isCanceled) {
                    logger.warn('get transactions cancelled', err);
                }
            });
    }

    componentWillUnmount() {
        this._isCanceled = true;
        this.cancelToken?.cancel();
    }

    render() {
        const { accountId } = this.props
        const { response, loadedAccountId, error, editing } = this.state

        if (error) {
            return <>Error: {error}</>
        }

        if (loadedAccountId !== accountId || !response) {
            this.loadAccountTransactions(accountId)
            return <>Waiting</>
        }

        return <>
            <Button onClick={() => this.setState({ editing: !editing })}>toggle edit state</Button>

            {Object.keys(response.transactions).map(group => <div key={group}>
                <h2>{group}</h2>
                <Table rowKey={t => t.id} dataSource={response.transactions[group]}>
                    <Table.Column title="Timestamp" dataIndex="timestamp" key="timestamp" />
                    <Table.Column title="Description" dataIndex="description" key="description" />
                    <Table.Column title="Amount" dataIndex="amount" key="amount" />
                    <Table.Column title="Source" dataIndex="source" key="source" />
                    {editing && <Table.Column key="delete" render={(transaction: Transaction) =>
                        <Space size="middle" onClick={() => {
                            TransactionApi.transactions.delete(accountId, group, transaction.id)
                                .then(_ => this.loadAccountTransactions(accountId))
                                .catch(x => logger.warn(x))
                        }}>
                            <a>Delete</a>
                        </Space>} />}
                </Table>
            </div>)
            }
        </>
    }
}
